import React, { Component } from "react"
import { isValidDomain, isValidUrl } from "../../helpers/constants"
import { FormItem } from "./form-item"
import { Translate } from "../../components/translations"
import { Input } from "./input"
import Button from "../button/index"
import { Form } from "./index"
import { DomainFormComponentProps } from "../../types"

export class DomainForm extends Component<DomainFormComponentProps> {
  state = {
    domainInput: "",
    hasError: false,
  }

  componentWillMount(): void {
    const { value } = this.props
    // @ts-ignore
    if (value && global.window && value.length > 2) {
      this.setState({
        domainInput: value.trim().toLowerCase(),
        hasError: !this.validate(value.trim().toLowerCase()),
      })
    }
  }

  componentWillReceiveProps(nextProps: DomainFormComponentProps) {
    const { value } = nextProps;
    // @ts-ignore
    if (value && global.window && value.length > 2) {
      this.setState({
        domainInput: value.trim().toLowerCase(),
        hasError: !this.validate(value.trim().toLowerCase()),
      })
    }
  }

  validate = (value: string) =>
    // @ts-ignore
    this.props.url && global.window ? isValidUrl(value) : isValidDomain(value)

  onChange = (e: React.FormEvent) => {
    const value = (e.target as HTMLInputElement).value;
    this.setState({ domainInput: value.trim().toLowerCase(), hasError: false })
  }

  onSubmit = () => {
    const hasError = !this.validate(this.state.domainInput)
    if (hasError) {
      this.setState({ hasError })
    } else {
      this.props.onSubmit(this.state.domainInput)
    }
  }

  render() {
    const { domainInput, hasError } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <FormItem className="input-group subcribes hero-input-group">
          <Translate name="HOME_INPUT_PLACEHOLDER">
            {text => (
              <Input
                data-testid="input"
                className="url-input"
                type="text"
                name="url"
                onChange={this.onChange}
                value={domainInput || ""}
                placeholder={text}
              />
            )}
          </Translate>
          <Button primary className="btn_submit">
            {this.props.monitor ? <Translate name="START_MONITORING"/> : <Translate name="GET_STATS" />}
          </Button>
          {hasError ? (
            <div
              className="alert alert-danger text-center p-2 f_size_15"
              style={{ width: "100%", marginTop: 5 }}
            >
              <Translate name="INVALID_DOMAIN_NAME_OR_URL" />
            </div>
          ) : (
            <div />
          )}
        </FormItem>
      </Form>
    )
  }
}
