import React, {FC, useState} from 'react';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import {Modal} from '../../components/modal';
import Button from '../../components/button/index';

import {getUserToken, saveUptimePageUrl} from '../../helpers';
import {ScanResultsPageProps} from '../../types';

import uptimeMonitoringImg from '../../img/home-tools/uptime-monitoring.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

import './styles.scss';

const UptimeMonitoring: FC<ScanResultsPageProps> = props => {
  const url = props['*'];
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (value: string) => {
    if (getUserToken()) {
      let url = value;
      saveUptimePageUrl(url);

      navigate('/pricing/');
    } else {
      setShowModal(!showModal);
    }
  };
  return (
    <Layout>
      <PageHero
        title={'Free & reliable website uptime monitoring tool'}
        subtitle="Monitor any URL and get instant alerts when downtime is detected."
      >
        <DomainForm value={url} onSubmit={handleSubmit} monitor />
      </PageHero>
      <Section>
        <div className="col-md-3">
          <ToolTabs active="uptime-monitoring" url={url} />
        </div>
        <div className="col-md-9 tool-detail">
          <div className="row">
            <div className="col-md-4" style={{marginBottom: '20px'}}>
              <img src={uptimeMonitoringImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
            </div>
            <div className="col-md-8">
              <p>
                <strong>What is uptime monitoring?</strong>
              </p>
              <p>
                The internet is fragile and downtime can affect websites of all sizes, from Google and Amazon, to
                independent eCommerce sites. The goal of every website owner is to keep uptime as high as possible and
                detect issues as fast as possible to minimize the cost of downtime.
              </p>
              <p>
                Uptime monitoring keeps a close eye on your website or landing pages for page response time and
                downtime.
              </p>
            </div>
            <div className="col-md-12">
              <p>
                Our free website uptime monitoring service checks your URL every five minutes notifying you via email
                when problems occur so you can fix issues before they affect your business.
              </p>
              <p>
                Uptime monitoring is ideal to check any URL, email server, DNS, CDN, SMTP, POP3, RADIUS or 3rd party
                service for downtime.
              </p>
            </div>
          </div>
          <div className="row" style={{marginTop: '30px'}}>
            <div className="col-md-6" style={{margin: 'auto'}}>
              <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
            </div>
            <div className="col-md-6">
              <div className="flex-align-center">
                <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                <p>
                  Harness the internet as your own data source, build your own scraping bots and leverage ready made
                  automations to delegate time consuming tasks and scale your business.
                </p>
                <p>No coding or PhD in programming required.</p>
                <div>
                  <Button
                    hexomaticCTA
                    className="monitor_btn"
                    onClick={() =>
                      (window.location.href = 'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                    }
                  >
                    Get started in minutes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {showModal && (
        <Modal
          isOpen={true}
          modalToggle={() => {}}
          title="LOGIN_TO_CONTINUE"
          modalFooter={
            <Link to="/#login" state={{goBack: true}}>
              <button type="button" className="btn btn-secondary">
                <Translate name="GO_TO_LOGIN_PAGE" />
              </button>
            </Link>
          }
        >
          <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
        </Modal>
      )}
    </Layout>
  );
};

export default UptimeMonitoring;
